import React, {useEffect} from 'react';
import {Column} from '@xo-union/tk-component-grid';
// eslint-disable-next-line
import '@tkww/ads-content-component-jwplayer-wrapper/styles.couplesearch';
import setup from '@tkww/ads-content-component-jwplayer-wrapper';

import style from './style.scss';

const FeaturedVideo = () => {
  useEffect(() => {
    setup({
      renderContainerId: 'jwplayer-video',
      addElementWatcher: false,
      renderTitle: false,
      floatingWidgetOptions: {
        title: false,
        headline: 'Featured Registry Video From The Knot',
      },
    });
  }, []);

  return (
    <Column xs="12" name="featuredVideoContainer" data-testid="featuredVideo">
      <div className={style['featured-video']}>
        <div id="jwplayer-video" />
      </div>
    </Column>
  );
};

export default FeaturedVideo;
