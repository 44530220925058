import React, {createContext, useState} from 'react';
import PropTypes from 'prop-types';
import {scroller} from 'react-scroll';
import {SORT_ASC, SORT_DESC} from '../constants';
import fetchSearchResults, {PER_PAGE} from '../utils/fetchSearchResults';
import track from '../utils/track';

const DEFAULT_PAGE = 1;
const DEFAULT_SORT = {sortBy: '', sortValue: ''};

export const SearchContext = createContext({
  currentResults: [],
  handlePaginate: () => {},
  handleSort: () => {},
  handleSubmit: () => {},
  hasError: false,
  is404: false,
  isLoading: false,
  page: 1,
  query: '',
  setIs404: () => {},
  sortParams: DEFAULT_SORT,
  totalPages: undefined,
  totalResults: 0,
});

export const SearchContextProvider = ({children}) => {
  const [allResults, setAllResults] = useState([]);
  const [currentResults, setCurrentPaginatedResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [is404, setIs404] = useState(false);
  const [sortParams, setSortParams] = useState(DEFAULT_SORT);

  const getPaginatedResults = (forPage, initialResults) => {
    const results = initialResults || allResults;
    const start = (forPage - 1) * PER_PAGE;
    const paginatedResults = results.slice(start, start + PER_PAGE);
    setCurrentPaginatedResults(paginatedResults);
  };

  const fetchResults = async (fetchParams, shouldTrack = false) => {
    setHasError(false);
    setIsLoading(true);

    const {records = [], error} = await fetchSearchResults(fetchParams);
    setIsLoading(false);

    if (error) {
      setHasError(true);
    } else {
      if (fetchParams.page === DEFAULT_PAGE) {
        setAllResults(records);
        getPaginatedResults(fetchParams.page, records);
      } else {
        getPaginatedResults(fetchParams.page);
      }

      setTotalResults(records.length);
      setPage(fetchParams.page);

      if (shouldTrack) {
        track({
          event: 'Couple Search',
          resultsReturned: records.length,
          searchFirstName: fetchParams.firstName,
          searchLastName: fetchParams.lastName,
          searchWeddingDate: `${fetchParams.month}-${fetchParams.year}`,
          source: 'couple search results page',
        });
      }
    }
  };

  const handleSubmit = async ({firstName, lastName, month, year}) => {
    if (is404) {
      window.history.replaceState(null, null, window.location.pathname);

      setIs404(false);
    }

    await fetchResults(
      {
        firstName,
        lastName,
        month,
        year,
        page: DEFAULT_PAGE,
      },
      true,
    );
    setSortParams(DEFAULT_SORT);
    setQuery({firstName, lastName, month, year});
  };

  const handlePaginate = async ({page: newPage}) => {
    if (newPage > page) {
      track({
        event: 'Results Viewed',
        pageCount: page,
        position: 0,
        selected: 'clicked to next page',
      });
    } else {
      track({
        event: 'Results Viewed',
        pageCount: page,
        position: 0,
        selected: 'clicked to previous page',
      });
    }

    scroller.scrollTo('resultsContainer', {
      duration: 500,
      delay: 100,
      smooth: true,
    });

    setPage(newPage);
    getPaginatedResults(newPage);
  };

  const handleSort = async sortBy => {
    let sortValue = SORT_ASC;

    if (sortBy === sortParams.sortBy) {
      sortValue = sortParams.sortValue === SORT_ASC ? SORT_DESC : SORT_ASC;
    }

    await fetchResults({
      ...query,
      page: DEFAULT_PAGE,
      sortBy,
      sortValue,
    });

    setPage(DEFAULT_PAGE);
    setSortParams({sortBy, sortValue});
  };

  return (
    <SearchContext.Provider
      value={{
        currentResults,
        handlePaginate,
        handleSort,
        handleSubmit,
        hasError,
        is404,
        isLoading,
        page,
        query,
        setIs404,
        sortParams,
        totalPages: Math.ceil(totalResults / PER_PAGE),
        totalResults,
      }}>
      {children}
    </SearchContext.Provider>
  );
};

SearchContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
