import React, {useContext, useEffect} from 'react';
import {Row, Column} from '@xo-union/tk-component-grid';
import Spinner from '@xo-union/tk-component-spinner';
import {Subhead} from '@xo-union/tk-ui-typography';
import {Pagination} from '@xo-union/tk-ui-pagination';
import queryString from 'query-string';
import ResultsHeader from './components/ResultsHeader';
import ResultRow from './components/ResultRow';
import SearchForm from './components/SearchForm';
import FeaturedVideo from './components/FeaturedVideo';
import {SearchContext} from '../../contexts/SearchContext';
import generateTestId from '../../utils/generateTestId';
import style from './style.scss';

const CoupleSearch = () => {
  const {
    currentResults,
    handlePaginate,
    hasError,
    is404,
    isLoading,
    page,
    query,
    setIs404,
    totalPages,
    totalResults,
  } = useContext(SearchContext);

  useEffect(() => {
    const {httpcode} = queryString.parse(window.location.search);
    setIs404(httpcode === '404');
  }, [setIs404]);

  return (
    <>
      <Row>
        <Column
          className={style.heading}
          xs="12"
          name="resultsContainer"
          data-testid="coupleSearch">
          <h1>
            {is404
              ? "We're sorry, we could not find the registry page."
              : "Find a couple's wedding registry and website"}
          </h1>
          <Subhead>
            {is404
              ? `Try searching for the couple by name and event date.`
              : `Going to a wedding? Search for either member of the lucky couple.`}
          </Subhead>
        </Column>
      </Row>
      <SearchForm />
      <Row>
        <Column
          xs="12"
          className={
            query && !hasError && !isLoading
              ? style['with-results']
              : style['before-search']
          }>
          {isLoading && <Spinner className={style.spinner} />}

          {query && !hasError && !isLoading && (
            <>
              <table className={style['results-table']}>
                {query && (
                  <caption className={style.summary}>
                    We found {totalResults} matches for{' '}
                    {`"${query.firstName} ${query.lastName}"`}
                  </caption>
                )}
                <thead>
                  <ResultsHeader />
                </thead>
                <tbody data-testid="results-table-body">
                  {currentResults.map((result, idx) => (
                    <ResultRow
                      eventDate={result.eventDate}
                      fianceFirstName={result.fianceFirstName}
                      fianceLastName={result.fianceLastName}
                      firstName={result.firstName}
                      gvrUrl={result.gvrUrl}
                      isHiddenDate={result.isHiddenDate}
                      key={result.id}
                      lastName={result.lastName}
                      position={idx + 1}
                      state={result.state}
                      wwsUrl={result.wwsUrl}
                    />
                  ))}
                </tbody>
              </table>

              {totalPages > 1 && (
                <div
                  id={generateTestId('pagination')}
                  className={style.pagination}>
                  <Pagination
                    activePage={page}
                    totalPageCount={totalPages}
                    onPageChange={handlePaginate}
                  />
                </div>
              )}
            </>
          )}

          {hasError && (
            <div className={style.error}>
              We&apos;re sorry, but an error has occurred. Please try your
              search again.
            </div>
          )}
        </Column>
      </Row>
      <Row>
        <FeaturedVideo />
      </Row>
    </>
  );
};

export default CoupleSearch;
