import React from 'react';
import PropTypes from 'prop-types';
import {Field} from '@xo-union/tk-component-fields';
import generateTestId from '../../../../utils/generateTestId';

const NameField = ({name, displayName, onChange, shouldValidate, value}) => {
  const isInvalid = shouldValidate && value.length < 2;

  return (
    <Field
      id={generateTestId(name)}
      name={name}
      value={value}
      onChange={e => onChange(e.target.value)}
      state={isInvalid ? 'invalid' : 'neutral'}
      subText={isInvalid ? `${displayName} needs 2 characters` : ''}
    />
  );
};

NameField.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  shouldValidate: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default NameField;
