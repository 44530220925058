import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {SearchContext} from '../../../../contexts/SearchContext';
import formatAsHttps from '../../../../utils/formatAsHttps';
import formatDate from '../../../../utils/formatDate';
import formatName from '../../../../utils/formatName';
import joinName from '../../../../utils/joinName';
import track from '../../../../utils/track';
import style from './style.scss';

const ResultRow = ({
  eventDate,
  fianceFirstName,
  fianceLastName,
  firstName,
  gvrUrl,
  isHiddenDate,
  lastName,
  position,
  state,
  wwsUrl,
}) => {
  const {page} = useContext(SearchContext);
  const registrant = formatName(joinName([firstName, lastName]));
  const coRegistrant = formatName(joinName([fianceFirstName, fianceLastName]));

  const handleLinkClick = () => {
    track({
      event: 'Results Viewed',
      pageCount: page,
      position,
      selected: wwsUrl ? 'website' : 'registry',
    });
  };

  return (
    <tr className={style.row} data-testid="results-list-item">
      <th className={style.registrant} scope="row">
        {registrant}
        {coRegistrant && (
          <span className={style['mobile-only']}>{` & ${coRegistrant}`}</span>
        )}
      </th>
      <td className={style['desktop-only']}>{coRegistrant}</td>
      <td className={style['wedding-date']}>
        <span className={style['mobile-only']}>Wedding Date:&nbsp;</span>
        {isHiddenDate ? 'To Be Announced' : formatDate(eventDate)}
      </td>
      <td className={style['wedding-location']}>{state || 'Other'}</td>
      <td className={cx(style['wws-url'], style['desktop-only'])}>
        {wwsUrl && (
          <a
            data-testid="wwws-link-desktop"
            href={formatAsHttps(wwsUrl)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleLinkClick}>
            Our Website
          </a>
        )}
      </td>
      <td className={style['registry-url']}>
        {wwsUrl && (
          <span className={style['mobile-only']}>
            <a
              data-testid="wwws-link-mobile"
              href={formatAsHttps(wwsUrl)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleLinkClick}>
              Our Website
            </a>
            <span className={style.separator}>|</span>
          </span>
        )}
        <a
          data-testid="registry-link"
          href={wwsUrl ? `${formatAsHttps(wwsUrl)}/registry` : gvrUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleLinkClick}>
          Our Registry
        </a>
      </td>
    </tr>
  );
};

ResultRow.propTypes = {
  eventDate: PropTypes.string.isRequired,
  fianceFirstName: PropTypes.string.isRequired,
  fianceLastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  gvrUrl: PropTypes.string.isRequired,
  isHiddenDate: PropTypes.bool,
  lastName: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  wwsUrl: PropTypes.string,
};

ResultRow.defaultProps = {
  isHiddenDate: false,
  wwsUrl: '',
};

export default ResultRow;
