export const CURRENT_YEAR = new Date().getFullYear();
export const EMPTY_NAME = '';
export const EMPTY_DATE = '0';
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const YEARS = [-1, 0, 1, 2].map(n => (CURRENT_YEAR + n).toString());
export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';
