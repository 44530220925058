import React from 'react';
import HeaderColumn from '../HeaderColumn';
import style from './style.scss';

const ResultsHeader = () => (
  <tr className={style.row} data-testid="results-header">
    <HeaderColumn
      value="Registrant"
      label="Registrant"
      className={style.registrant}
    />

    <HeaderColumn
      value="CoRegistrant"
      label="Co-Registrant"
      className={style['desktop-only']}
    />

    <HeaderColumn
      value="EventDate"
      className={style['desktop-only']}
      label="Wedding Date"
    />

    <HeaderColumn
      value="Location"
      className={style.location}
      label="Wedding Location"
    />
    <HeaderColumn className={style['desktop-only']} label="Wedding Website" />
    <HeaderColumn className={style['desktop-only']} label="Registry" />
  </tr>
);

export default ResultsHeader;
