import queryString from 'query-string';

const URL = `${process.env.CORE_API_HOST}/couples/public-details`;

const getDate = (year = 0, month = 0, day = 0) =>
  new Date(year, month, day).toISOString().split('T')[0];

const getDateParams = (month, year) => {
  if (year > 0 && month > 0) {
    // return a date range of the 1st thru the last day of selected month
    return {
      eventDateFrom: getDate(year, month - 1, 1),
      eventDateTo: getDate(year, month, 0),
    };
  }

  if (year > 0 && month === 0) {
    // return the full selected year (Jan 1st thru Dec 31st)
    return {
      eventDateFrom: getDate(year, 0, 1),
      eventDateTo: getDate(year, 11, 31),
    };
  }

  return {};
};

const getSortParams = (sortValue, sortBy) => {
  const sortKeys = {
    Registrant: 'firstName,lastName',
    CoRegistrant: 'fianceFirstName,fianceLastName',
    EventDate: 'eventDate',
    Location: 'state,country',
  };

  const directions = {
    ASC: '',
    DESC: '-',
  };

  const sort = directions[sortValue] + sortKeys[sortBy];

  return {sort};
};

export const PER_PAGE = 20;

export default async ({
  firstName,
  lastName,
  month,
  scrollId,
  sortBy,
  sortValue,
  year,
}) => {
  const queryParams = {
    eventType: 'wedding',
    firstName: firstName && firstName.replace('’', "'"),
    lastName: lastName && lastName.replace('’', "'"),
    pagesize: 1000,
    scrollId,
    ...getDateParams(Number(month), Number(year)),
    ...getSortParams(sortValue, sortBy),
  };

  try {
    const response = await fetch(
      `${URL}?${queryString.stringify(queryParams)}`,
    );

    return response.json();
  } catch {
    return {error: true};
  }
};
