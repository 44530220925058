import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {SortHeader} from '@xo-union/tk-component-tables';
import {SORT_ASC} from '../../../../constants';
import {SearchContext} from '../../../../contexts/SearchContext';
import generateTestId from '../../../../utils/generateTestId';
import style from './style.scss';

const HeaderColumn = ({className, value, label}) => {
  const {handleSort, sortParams} = useContext(SearchContext);
  const {sortBy, sortValue} = sortParams;

  const isActive = sortBy === value;
  const isSortable = value !== '';

  return (
    <th
      aria-sort={sortValue || 'none'}
      data-testid="header-column"
      className={cx(style.column, className, {
        [style.nosort]: !isSortable,
      })}
      id={generateTestId(value.toLowerCase())}>
      <SortHeader
        labelClass={cx(style.header, {
          [style.nosort]: !isSortable,
        })}
        onClick={() => handleSort && handleSort(value)}
        label={label}
        active={isActive}
        asc={isSortable && isActive && sortValue === SORT_ASC}
      />
    </th>
  );
};

HeaderColumn.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
};

HeaderColumn.defaultProps = {
  className: '',
  value: '',
};

export default HeaderColumn;
