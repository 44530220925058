const formatDate = date => {
  if (!date) return '';

  try {
    const theDate = new Date(date);
    const day = theDate.getUTCDate();
    const month = theDate.getUTCMonth() + 1;
    const year = theDate.getUTCFullYear();

    return `${month}-${day}-${year}`;
  } catch (err) {
    return '';
  }
};

export default formatDate;
