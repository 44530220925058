import React, {useState, useEffect, createRef} from 'react';
import PropTypes from 'prop-types';
import MembershipService from '@xo-union/sdk-membership';
import {CookieStorage} from 'cookie-storage';
import HeaderNav from '@xo-union/tk-component-header-nav/lib/remote';
import MembershipModal from '@xo-union/tk-component-membership-modal';
import affiliateAnalytics from '@xo-union/affiliate-marketing-analytics';
import sha1 from 'crypto-js/sha1';

const isProd = process.env.ENV === 'production';
const sessionToken = new CookieStorage().getItem('xo-session-token');
const membershipService = new MembershipService({
  clientOptions: {
    env: isProd ? 'production' : 'qa',
    apiKey: process.env.MEMBERSHIP_API_KEY,
    version: 'v2',
  },
});

const HeaderNavWithMembership = ({headerProps}) => {
  const [couplePhotoUrl, setCouplePhotoUrl] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const membershipModalRef = createRef();

  useEffect(() => {
    const getMember = async () => {
      const result = await membershipService.getMember({
        token: sessionToken,
      });
      if (result.response.body.members) {
        const {
          couple_photo_url: theCouplePhotoUrl,
          email,
          id,
        } = result.response.body.members;
        setCouplePhotoUrl(theCouplePhotoUrl);
        setIsLoggedIn(true);

        affiliateAnalytics.identify({
          customerId: id,
          customerEmail: sha1(email),
        });
      }
    };
    if (sessionToken) getMember();
  }, []);

  const openSignUpModal = () => setOpenModal('SIGN_UP');
  const openLogInModal = () => setOpenModal('LOG_IN');
  const handleLogOut = async () => {
    await membershipService.logOut();
    setIsLoggedIn(false);
  };
  const closeModal = () => setOpenModal('');
  const handleSignUp = async e => {
    try {
      await membershipService.signUp(e.data);
      setIsLoggedIn(true);
      closeModal();
    } catch (err) {
      let message = 'Oops, something went wrong.';

      if (e.userFacingError) {
        message = e.userFacingError;
      }

      membershipModalRef.current.showError(message);
    }
  };
  const handleLogIn = async e => {
    try {
      await membershipService.logIn(e.data);
      setIsLoggedIn(true);
      closeModal();
    } catch (err) {
      let message = 'Oops, something went wrong.';

      if (e.userFacingError) {
        message = e.userFacingError;
      }

      membershipModalRef.current.showError(message);
    }
  };

  return (
    <>
      <HeaderNav
        {...headerProps}
        activeProduct="registry"
        couplePhotoUrl={couplePhotoUrl}
        loggedIn={isLoggedIn}
        onClickLogIn={openLogInModal}
        onClickSignUp={openSignUpModal}
        onClickLogOut={handleLogOut}
      />
      <MembershipModal
        ref={membershipModalRef}
        metadata={{
          applicationName: `${isProd ? 'Test_' : ''}Registry`,
          userAction: 'header',
        }}
        openModal={openModal || null}
        onClickLogIn={openLogInModal}
        onClickSignUp={openSignUpModal}
        onClose={closeModal}
        onSignUp={handleSignUp}
        onLogIn={handleLogIn}
        inProgress={false}
      />
    </>
  );
};

HeaderNavWithMembership.propTypes = {
  headerProps: PropTypes.shape({}).isRequired,
};

export default HeaderNavWithMembership;
