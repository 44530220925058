import {MONTHS} from '../constants';

const formatMonth = (month, reformatFromQueryString = false) => {
  let formattedMonth;
  let idx;

  if (reformatFromQueryString) {
    idx = Number(month) - 1;
    formattedMonth = MONTHS[idx];
  } else {
    idx = MONTHS.indexOf(month) + 1;
    formattedMonth =
      idx > 0 && idx < 10 ? `0${idx}`.slice(-2).toString() : idx.toString();
  }

  return formattedMonth;
};

export default formatMonth;
