import React, {useEffect, useRef} from 'react';
import gpt from '@tkww/xo.ads.gpt';
import isMobile from '../../utils/isMobile';
import style from './style.scss';

const Ad = () => {
  const adElementRef = useRef();

  useEffect(() => {
    const deviceType = isMobile() ? 'Mobile' : 'Desktop';
    const adPosition = isMobile() ? 'm728top' : '728top';
    const size = isMobile()
      ? [[320, 50]]
      : [
          [970, 90],
          [728, 90],
        ];
    const applicationName = 'Registry';
    const configPresets = new gpt.configPresets.TheKnotCore({
      applicationName,
    });

    configPresets.enableBidding = true;

    gpt.initialize(configPresets);
    gpt.addAsyncSlot({
      idSelector: 'registry',
      sync: true,
      sizes: size,
      targeting: [
        ['pos', adPosition],
        ['sid', 'couplesearch'],
        ['page-type', 'results'],
        ['wedding_roles', 'wedding-guest'],
        ['wedding-roles', 'wedding-guest'],
        ['mtdevicetype', deviceType.toLowerCase()],
        ['device-type', deviceType.toLowerCase()],
      ],
    });

    gpt.renderAds();
  }, []);

  return (
    <div
      className={`${style['ad-container']} ${style.hidden}`}
      ref={adElementRef}>
      <div id="registry" />
    </div>
  );
};

export default Ad;
