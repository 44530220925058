import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import AdBanner from './components/AdBanner';
import AppLayout from './components/AppLayout';
import {SearchContextProvider} from './contexts/SearchContext';
import CoupleSearch from './views/CoupleSearch';

const NoMatchRedirect = () => <Redirect to={{pathname: '/'}} />;

const CoupleSearchView = () => (
  <AppLayout>
    <AdBanner />
    <SearchContextProvider>
      <CoupleSearch />
    </SearchContextProvider>
  </AppLayout>
);

const App = () => (
  <Switch>
    <Route component={CoupleSearchView} exact path="/registry/couplesearch" />
    <Route component={NoMatchRedirect} />
  </Switch>
);

export default App;
