import '@xo-union/tk-ui-essentials';
import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@xo-union/tk-component-layout';
import {TopLevelContainer} from '@xo-union/tk-component-grid';
import FooterNav from '@xo-union/tk-component-footer-nav/lib/remote';
import Header from '../Header';
// eslint-disable-next-line no-unused-vars
import style from './style.scss'; // loads styles that pin footer to bottom

const analyticsProps = {product: 'couple search'};
const hostEnv = process.env.NODE_ENV === 'production' ? 'production' : 'qa';

const AppLayout = ({children}) => (
  <Layout
    header={
      <Header
        headerProps={{
          analyticsProps,
          ssi: true,
        }}
      />
    }
    footer={
      <FooterNav ssi analyticsProps={analyticsProps} knotHostEnv={hostEnv} />
    }>
    <TopLevelContainer>{children}</TopLevelContainer>
  </Layout>
);

AppLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

AppLayout.defaultProps = {
  children: [],
};

export default AppLayout;
