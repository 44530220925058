import React, {useContext, useState, useEffect} from 'react';
import queryString from 'query-string';
import {Row, Column} from '@xo-union/tk-component-grid';
import {Select, Option} from '@xo-union/tk-component-fields';
import {FormRow, FormColumn} from '@xo-union/tk-component-form-grid';
import {Button} from '@xo-union/tk-component-buttons';
import SearchNameField from '../SearchNameField';
import {EMPTY_DATE, EMPTY_NAME, MONTHS, YEARS} from '../../../../constants';
import {SearchContext} from '../../../../contexts/SearchContext';
import formatMonth from '../../../../utils/formatMonth';
import hasValidYear from '../../../../utils/hasValidYear';
import generateTestId from '../../../../utils/generateTestId';
import style from './style.scss';

const SearchForm = () => {
  const {handleSubmit} = useContext(SearchContext);
  const [formFirstName, setFirstName] = useState(EMPTY_NAME);
  const [formLastName, setLastName] = useState(EMPTY_NAME);
  const [formMonth, setMonth] = useState(EMPTY_DATE);
  const [formYear, setYear] = useState(EMPTY_DATE);
  const [shouldValidate, setShouldValidate] = useState(false);

  const handleSearch = ({firstName, lastName, month: initialMonth, year}) => {
    const month = formatMonth(initialMonth);
    const isValid =
      firstName.length >= 2 &&
      lastName.length >= 2 &&
      hasValidYear({formMonth, formYear, month, year});

    if (isValid) {
      handleSubmit({
        firstName,
        lastName,
        month,
        year,
      });
      setShouldValidate(false);
    } else {
      setShouldValidate(true);
    }
  };

  useEffect(() => {
    const initialize = () => {
      const {firstName, lastName, eventMonth, eventYear} = queryString.parse(
        window.location.search,
      );

      if (firstName || lastName || eventMonth || eventYear) {
        if (firstName) setFirstName(firstName);
        if (lastName) setLastName(lastName);

        const month = formatMonth(eventMonth, true);
        if (eventMonth) setMonth(month);
        if (eventYear) setYear(eventYear);

        handleSearch({
          firstName: firstName || formFirstName,
          lastName: lastName || formLastName,
          month: month || formMonth,
          year: eventYear || formYear,
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row hGutters={false}>
      <Column xs="12">
        <form
          data-testid="search-form"
          className={style['search-form']}
          onSubmit={e => {
            e.preventDefault();
            handleSearch({
              firstName: formFirstName,
              lastName: formLastName,
              month: formMonth,
              year: formYear,
            });
          }}>
          <FormRow>
            <FormColumn xs="6" md="6" lg="3">
              <SearchNameField
                name="first-name"
                displayName="First Name"
                value={formFirstName}
                onChange={setFirstName}
                shouldValidate={shouldValidate}
              />
            </FormColumn>
            <FormColumn xs="6" md="6" lg="3">
              <SearchNameField
                name="last-name"
                displayName="Last Name"
                value={formLastName}
                onChange={setLastName}
                shouldValidate={shouldValidate}
              />
            </FormColumn>
            <FormColumn xs="6" md="6" lg="2">
              <div id={generateTestId('month')} data-testid="search-form-month">
                <Select
                  name="month"
                  value={formMonth}
                  onChange={({option}) => setMonth(option.value)}
                  type="button">
                  {MONTHS.map(m => (
                    <Option
                      data-testid={`search-month-${m}`}
                      key={`month-${m}`}
                      label={m}
                      value={m}
                    />
                  ))}
                </Select>
              </div>
            </FormColumn>
            <FormColumn xs="6" md="6" lg="2">
              <div id={generateTestId('year')} data-testid="search-form-year">
                <Select
                  name="year"
                  value={formYear}
                  onChange={({option}) => setYear(option.value)}
                  state={
                    shouldValidate && !hasValidYear({formMonth, formYear})
                      ? 'invalid'
                      : 'neutral'
                  }
                  subText={
                    shouldValidate && !hasValidYear({formMonth, formYear})
                      ? 'If you’re looking to filter your results by date, please let us know the event year.'
                      : ''
                  }
                  type="button">
                  {YEARS.map(y => (
                    <Option
                      data-testid={`search-year-${y}`}
                      key={`year-${y}`}
                      label={y}
                      value={y.toString()}
                    />
                  ))}
                </Select>
              </div>
            </FormColumn>
            <FormColumn xs="12" md="12" lg="2">
              <Button
                block
                className={style['submit-button']}
                type="submit"
                id={generateTestId('submit')}>
                Search
              </Button>
            </FormColumn>
          </FormRow>
        </form>
      </Column>
    </Row>
  );
};

export default SearchForm;
