import '@xo-union/tk-ui-essentials';
import React from 'react';
import ReactDOM from 'react-dom';
import HoneyBadger from 'honeybadger-js';
import {BrowserRouter} from 'react-router-dom';
import App from './App';

if (window && window.analytics) window.analytics.page();

if (process.env.NODE_ENV === 'production') {
  // Setup HoneyBadger
  const honeyBadgerConf = {
    apiKey: process.env.HONEYBADGER_API_KEY,
    environment: process.env.NODE_ENV,
    revision: process.env.LATEST_COMMIT_SHA,
  };

  HoneyBadger.configure(honeyBadgerConf);
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
