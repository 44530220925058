import analyticsWrapper from '@xo-union/tk-analytics';
import isMobile from './isMobile';

const track = ({event, ...props}) => {
  analyticsWrapper.track(event, {
    platform: isMobile() ? 'mobile' : 'web',
    product: 'couple search',
    ...props,
  });
};

export default track;
